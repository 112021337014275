import { template as template_ae17fff4b00b4a7595623d1c1f95dd42 } from "@ember/template-compiler";
const FKLabel = template_ae17fff4b00b4a7595623d1c1f95dd42(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
